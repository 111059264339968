.modal-root__modal {
  pointer-events: auto;
  display: flex;
  z-index: 9999;
  max-height: 100%;
  overflow-y: hidden;
}

.media-modal {
  // https://stackoverflow.com/a/8468131
  @apply w-full h-full absolute inset-0;

  .audio-player.detailed,
  .extended-video-player {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .audio-player {
    max-width: 800px;
    max-height: 600px;
  }

  .extended-video-player {
    width: 100%;
    height: 100%;

    video {
      max-width: $media-modal-media-max-width;
      max-height: $media-modal-media-max-height;
    }
  }
}

.media-modal__closer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.media-modal__navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s linear;
  will-change: opacity;

  * {
    pointer-events: auto;
  }

  &.media-modal__navigation--hidden {
    opacity: 0;

    * {
      pointer-events: none;
    }
  }
}

.media-modal__nav {
  background: rgba($base-overlay-background, 0.5);
  box-sizing: border-box;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 24px;
  height: 20vmax;
  margin: auto 0;
  padding: 30px 15px;
  position: absolute;
  top: 0;
  bottom: 0;

  @media screen and (max-width: 600px) { padding: 30px 2px; }

  .svg-icon {
    width: 24px;
    height: 24px;
  }
}

.media-modal__nav--left {
  left: 0;
}

.media-modal__nav--right {
  right: 0;
}

.media-modal__pagination {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 20px;
  pointer-events: none;
}

.media-modal__meta {
  text-align: center;
  position: absolute;
  left: 0;
  bottom: 20px;
  width: 100%;
  pointer-events: none;

  &--shifted {
    bottom: 62px;
  }

  a {
    text-decoration: none;
    font-weight: 500;
    color: #fff;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
}

.media-modal__page-dot {
  display: inline-block;
}

.media-modal__button {
  background-color: #fff;
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin: 10px;
  padding: 0;
  border: 0;
  font-size: 0;
}

.media-modal__button--active {
  @apply bg-accent-500;
}

.media-modal__close {
  position: absolute;
  right: 8px;
  top: 8px;
  height: 48px;
  width: 48px;
  z-index: 100;
  color: #fff;

  .svg-icon {
    height: 48px;
    width: 48px;
  }
}

.error-modal {
  background: var(--background-color);
  color: var(--primary-text-color);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.error-modal__body {
  height: 80vh;
  width: 80vw;
  max-width: 520px;
  max-height: 420px;
  position: relative;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    user-select: text;
  }
}

.error-modal__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.error-modal__footer {
  flex: 0 0 auto;
  background: var(--background-color);
  display: flex;
  justify-content: center;
  padding: 25px;

  & > div {
    min-width: 33px;
  }

  .error-modal__nav {
    color: var(--highlight-text-color);
    border: 0;
    font-size: 14px;
    font-weight: 500;
    padding: 10px 25px;
    line-height: inherit;
    height: auto;
    margin: -10px;
    border-radius: 4px;
    background-color: transparent;

    &:hover,
    &:focus,
    &:active {
      color: var(--primary-text-color--faint);
      background-color: var(--background-color);
    }
  }
}

.actions-modal {
  position: relative;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid var(--background-color);
  color: var(--primary-text-color--faint);

  .dropdown-menu__separator {
    @apply block m-2 h-[1px] bg-gray-200 dark:bg-gray-600;
  }
}

.actions-modal {
  @apply w-full max-h-full max-w-lg m-auto mb-2 bg-white dark:bg-gray-800;

  .status {
    overflow-y: auto;
    max-height: 300px;
  }

  .actions-modal__item-label { font-weight: 500; }

  ul {
    @apply my-2 flex-shrink-0 overflow-y-auto;
    max-height: calc(100vh - 147px);

    // NOTE - not sure what this is yet, leaving alone for now until I find out.
    &.with-status { max-height: calc(80vh - 75px); }

    li:not(:empty) {
      a,
      button {
        @apply flex items-center px-4 py-3 text-gray-600 dark:text-gray-300 no-underline hover:bg-gray-100 dark:bg-gray-800 hover:text-gray-800 dark:hover:text-gray-200 text-left;

        &.destructive {
          @apply text-danger-600;
        }

        .svg-icon:first-child {
          @apply min-w-[1.25rem] w-5 h-5;

          svg {
            stroke-width: 1.5;

            &.feather {
              // Feather icons are a little larger
              transform: scale(0.9);
            }
          }
        }
      }

      button[type='button'] {
        @apply w-full justify-center text-center;
      }
    }
  }
}

.reply-mentions-modal__accounts {
  display: block;
  flex-direction: row;
  flex: 1;
  overflow-y: auto;
  min-height: 300px;
}

.remote-interaction-modal {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    button {
      align-self: flex-end;
    }
  }

  &__divider {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 -10px;

    &::before,
    &::after {
      @apply border-b border-gray-300 dark:border-gray-600;
      content: '';
      flex: 1;
    }
  }

  @media screen and (max-width: 895px) {
    margin: 0;
    border-radius: 6px;
    height: unset !important;
    width: 440px !important;
  }

  @media screen and (max-width: 480px) {
    width: 330px !important;
  }
}
